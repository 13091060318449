import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
  },
  inner: {
    minHeight: 500,
    minWidth: 1050,
  },
  progressBar: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
  root: {},
  tableRow: {
    cursor: 'pointer',
  },
}));

const LocationTable = props => {
  const { className, isLoading, locations, selectLocation, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>Owner Email</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {locations.map(location => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={location.id}
                    onClick={() => {
                      selectLocation(location);
                    }}
                  >
                    <TableCell>{location.name}</TableCell>
                    <TableCell>{location.address}</TableCell>
                    <TableCell>{location.ownerName}</TableCell>
                    <TableCell>{location.ownerEmail}</TableCell>
                    <TableCell>{location.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {isLoading ? <CircularProgress className={classes.progressBar} /> : null}
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

LocationTable.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  selectLocation: PropTypes.func.isRequired,
};

LocationTable.defaultProps = {
  className: '',
};

export default LocationTable;
