import axios from 'axios';

export default {
  add: data => axios({
    data,
    method: 'post',
    url: '/locations',
  }),
  get: () => axios({
    method: 'get',
    url: '/locations',
  }),
  update: (id, data) => axios({
    data,
    method: 'put',
    url: `/locations/${id}`,
  }),
};
