export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const API_URL = IS_PRODUCTION
  ? 'https://adrenaline-box.herokuapp.com/api'
  : 'http://localhost:3001/api';

export const DAYS = [
  {
    code: 'mon',
    label: 'Monday',
  },
  {
    code: 'tue',
    label: 'Tuesday',
  },
  {
    code: 'wed',
    label: 'Wednesday',
  },
  {
    code: 'thu',
    label: 'Thursday',
  },
  {
    code: 'fri',
    label: 'Friday',
  },
  {
    code: 'sat',
    label: 'Saturday',
  },
  {
    code: 'sun',
    label: 'Sunday',
  },
];
