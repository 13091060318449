import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LocationsAPI } from '../../api';
import { LocationForm, LocationTable, LocationToolbar } from './components';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(3),
  },
}));

const LocationList = props => {
  const { enqueueSnackbar } = props;

  const classes = useStyles();

  const [formErrors, setFormErrors] = useState({});
  const [isDrawerOpen, setDrawer] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, selectLocation] = useState(null);

  const onAddLocation = () => {
    setDrawer(true);
  };

  const onSelectLocation = location => {
    setDrawer(true);
    selectLocation(location);
  };

  const loadLocations = async () => {
    try {
      setLoading(true);

      const res = await LocationsAPI.get();

      setLocations(res.data);
    } catch (error) {
      const errorMsg = error.message;
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForm = async ({ id, ...data }) => {
    try {
      setLoading(true);

      let res;

      if (id) {
        res = await LocationsAPI.update(id, data);
      } else {
        res = await LocationsAPI.add(data);
      }

      await loadLocations();

      setFormErrors({});
      setDrawer(false);

      enqueueSnackbar(res.data.message, { variant: 'success' });
    } catch (error) {
      let errorMsg = error.message;

      if (error.response.data.error && error.response.data.error.isValidation) {
        setFormErrors(error.response.data.error.fields);
        errorMsg = error.response.data.error.message;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLocations();
  }, []);

  return (
    <div className={classes.root}>
      <LocationToolbar disabled={isLoading} onAddLocation={onAddLocation} />

      <div className={classes.content}>
        <LocationTable
          isLoading={isLoading}
          locations={locations}
          selectLocation={onSelectLocation}
        />
      </div>

      <LocationForm
        buttonText={selectedLocation ? 'Update Location' : 'Add Location'}
        formErrors={formErrors}
        isOpen={isDrawerOpen}
        location={selectedLocation}
        locations={locations}
        onSubmit={onSubmitForm}
        onToggle={setDrawer}
        selectLocation={selectLocation}
      />
    </div>
  );
};

LocationList.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(LocationList);
